import { FC, useEffect, useState } from 'react'
import { Loader } from '@aurecon-creative-technologies/styleguide'

import { getSurveyAssets } from '../api/admin'
import { getErrorMessage } from '../helpers/getErrorMessage'
import SwipEngageIcon from '../assets/swipengage-logo.svg?react'

import Style from '../styles/Card.module.sass'

interface ICardLogoProps {
  logo: string | null
}

export const CardLogo: FC<ICardLogoProps> = ({ logo }) => {
  const [surveyLogo, setSurveyLogo] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchSurveyAsset = async () => {
      if (!logo) {
        setSurveyLogo(null)
        return
      }

      try {
        setLoading(true)
        const imageDataUrl = await getSurveyAssets({ fileName: logo })
        if (imageDataUrl) setSurveyLogo(imageDataUrl)
      } catch (error) {
        console.warn(`** Error: ${getErrorMessage(error)}`)
      } finally {
        setLoading(false)
      }
    }

    fetchSurveyAsset()
  }, [logo])

  if (loading)
    return (
      <div className={Style.logoWrapper}>
        <Loader />
      </div>
    )

  return (
    <div className={Style.logoWrapper}>
      {surveyLogo ? (
        <div className={Style.logo} style={{ backgroundImage: `url(${surveyLogo})` }} />
      ) : (
        <SwipEngageIcon />
      )}
    </div>
  )
}
