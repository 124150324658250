import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Container, Grid, useToast } from '@aurecon-creative-technologies/styleguide'
import { useAuth0 } from '@auth0/auth0-react'
import { format } from 'date-fns'

import AdminLayout from '../components/layout/AdminLayout'
import AddEditSurveyInfo from '../components/AddEditSurvey/AddEditSurveyInfo'
import AddEditSurveyMembers from '../components/AddEditSurvey/AddEditSurveyMembers'

import {
  updateSurveyDetails,
  createSurvey,
  updateSurveyMembers,
  IApiKeyDetails,
  generateAPIKey,
} from '../api/admin/survey'
import { FileWithPreview, IAdminSurvey, IAppInsightCustomEventProps } from '../api/model'
import { uploadPhotoStorage } from '../api/admin'
import { addQuestion } from '../api/admin/question'
import { PageEnum } from '../enums/PageTypesEnum'
import {
  Surveys,
  CurrentSurveyId,
  MainModal,
  useRefreshSurveys,
  NavbarSubTab,
  SurveyApiKey,
  useRefreshSurveyApiKey,
} from '../store/AdminStore'
import NavbarSubTabEnum from '../enums/NavbarSubTabEnum'

import { isGlobalAdmin } from '../helpers/isGlobalAdmin'
import { INTERNAL_EMAIL_REGEX } from '../config/config'
import { validateTextField } from '../helpers/surveyValidation'
import { FieldLimitEnum } from '../enums/FieldLimitEnum'
import { appInsights } from '../api/AppInsights'

import Style from '../styles/AddEditSurvey.module.sass'

export interface SurveyFormData {
  name: string
  headerText: string
  link: string
  enabled: string
  default: string
  email: string
  code: string
  project: string
  description: string
  branding: boolean
  logoBright: string | null
  logoBrightFile: File | null
  logoDark: string | null
  logoDarkFile: File | null
  no_preview: boolean
}

const initialValues: SurveyFormData = {
  name: '',
  headerText: '',
  link: '',
  enabled: 'no',
  default: 'no',
  email: '',
  code: '',
  project: '',
  description: '',
  branding: true,
  logoBright: null,
  logoBrightFile: null,
  logoDark: null,
  logoDarkFile: null,
  no_preview: false,
}

export interface FileData {
  [key: string]: string | File | FileData | null
}

const initialSurveyData = {
  author: '',
  default_survey: false,
  enabled: false,
  header_text: '',
  description: '',
  id: '',
  link: '',
  logos: '',
  mod_date: '',
  name: '',
  users: [],
  logo_bright: null,
  logo_dark: null,
  branding: true,
  scorecard: false,
  project_code: '',
  project_name: '',
  no_preview: false,
}

export interface ErrorData {
  [field: string]: string
}

type tabType = 'info' | 'members'

export type saveType = 'save' | 'saveDesign'

const AddEditSurvey: FC = () => {
  const existingSurveyId = useParams().survey_id
  const [tab, setTab] = useState<tabType>('info')
  const [surveyId] = useState(() => existingSurveyId || null)
  const [survey, setSurvey] = useState<IAdminSurvey>(initialSurveyData)
  const setMainModal = useSetRecoilState(MainModal)
  const refreshSurveys = useRefreshSurveys()
  const [users, setUsers] = useState<string[]>([])
  const [formValues, setFormValues] = useState<SurveyFormData>(initialValues)
  const [formErrors, setFormErrors] = useState<ErrorData>({})
  const setCurrentSurveyId = useSetRecoilState(CurrentSurveyId)
  const surveys = useRecoilValueLoadable(Surveys)
  const setNavbarSubTab = useSetRecoilState(NavbarSubTab)
  const surveyApiKey = useRecoilValueLoadable(SurveyApiKey)
  const [apiKey, setApiKey] = useState<IApiKeyDetails | null>(null)
  const { user } = useAuth0()
  const { addToast } = useToast()
  const refreshApiKey = useRefreshSurveyApiKey()
  const [apiKeyLoading, setApiKeyLoading] = useState<boolean>(false)

  const isAuthor = survey && survey.author === user?.email?.toLowerCase()
  const globalAdmin = user ? isGlobalAdmin(user) : false
  const isAdmin = isAuthor || globalAdmin
  const editMode = !!existingSurveyId

  useEffect(() => {
    setNavbarSubTab(NavbarSubTabEnum.INFO)
  }, [setNavbarSubTab])

  useEffect(() => {
    if (surveys.state === 'loading')
      setMainModal({
        title: 'Survey',
        text: 'Loading survey data...',
      })
    else setMainModal(null)
  }, [setMainModal, surveys.state])

  useEffect(() => {
    if (surveyApiKey.state !== 'hasValue') return
    if (surveyApiKey.state === 'hasValue' && !surveyApiKey.contents) {
      setApiKey(null)
    } else {
      setApiKey(surveyApiKey.contents)
    }
  }, [surveyApiKey])

  useEffect(() => {
    if (surveys.state !== 'hasValue' || !user || !user.email) return

    if (!editMode) {
      const author = user.email.toLowerCase()

      setSurvey((values) => {
        return {
          ...values,
          author,
        }
      })
      return
    }

    const data = surveys.contents.find((s) => s.id === surveyId)

    if (!data) {
      window.location.hash = '/surveys'
      return
    }

    setTimeout(() => {
      setFormValues({
        ...initialValues,
        name: data.name + '',
        headerText: data.header_text,
        link: data.link,
        enabled: data.enabled ? 'yes' : 'no',
        default: data.default_survey ? 'yes' : 'no',
        email: '',
        code: data.project_code || '',
        project: data.project_name || '',
        description: data.description,
        branding: data.branding,
        logoBright: data.logo_bright,
        logoDark: data.logo_dark,
        no_preview: data.no_preview,
      })
    })

    setSurvey(data)
    setUsers([...data.users])
    setCurrentSurveyId(surveyId)

    //eslint-disable-next-line
  }, [surveyId, surveys])

  const submitForm = async (type: saveType) => {
    const errors = Object.entries(formErrors).some((err) => !!err[1])

    if (errors) return

    setMainModal({
      title: 'Survey',
      text: 'Saving survey data...',
    })

    const data = await createPayload()

    let newSurveyId
    let hasError = false
    if (editMode && surveyId) {
      let resUsers: boolean | Response = false
      const usersToAdd = users.filter((addingUser) => !(survey as IAdminSurvey).users.includes(addingUser))
      const usersToRemove = (survey as IAdminSurvey).users.filter((removingUser) => !users.includes(removingUser))
      const resDetails = await updateSurveyDetails({ surveyId, data })
      if (usersToAdd.length || usersToRemove.length) {
        resUsers = await updateSurveyMembers({ surveyId, usersToAdd, usersToRemove })
      }
      hasError = handleResponse(resDetails, resUsers)
      if (!hasError) {
        addToast({
          type: 'success',
          message: `${data.name} Survey has been updated.`,
          timeout: 3000,
        })
      }
    } else {
      data.users = users
      const newSurvey = await createSurvey({ data })

      if (newSurvey.status === 400) {
        hasError = true
        handleDuplicateLinks()
      } else {
        const titleData = {
          type: PageEnum.TITLE,
          textBig: formValues.name,
          textSmall: '',
          background: '',
          photoMain: null,
          photoBg: null,
          validation: '',
          visible: '',
          screen: 0,
          confirmVisible: false,
        }
        await addQuestion({ surveyId: newSurvey.id, data: titleData })

        newSurveyId = newSurvey.id
        addToast({
          type: 'success',
          message: `${data.name} Survey has been created successfully.`,
          timeout: 3000,
        })

        const appInsightProps = {
          name: 'CreateSurvey',
          properties: {
            surveyId: newSurvey.id,
            surveyName: data.name,
            createdDateTime: new Date(),
            createdBy: user?.email,
          },
        }

        sendAppInsightEvent(appInsightProps)
      }
    }

    reloadSurveys(hasError, newSurveyId || surveyId, type)
  }

  const updateFile = async (field: string) => {
    const key = `${field}File` as keyof SurveyFormData
    const file = formValues[key] as FileWithPreview
    if (!file) return null

    const extention = file.name.split('.').pop()
    const timestamp = format(new Date(), 't')
    const name = `${field}-${surveyId}-${timestamp}.${extention}`

    await uploadPhotoStorage({ file, name })

    return name
  }

  useEffect(() => {
    const name = validateTextField({
      fieldValue: formValues.name,
      fieldName: 'survey name',
      charLimit: FieldLimitEnum.SURVEY_INFO,
      isRequired: true,
    })
    const headerText = validateTextField({
      fieldValue: formValues.headerText,
      fieldName: 'main text',
      charLimit: FieldLimitEnum.SURVEY_INFO,
      isRequired: true,
    })
    const link = validateTextField({
      fieldValue: formValues.link,
      fieldName: 'survey link',
      charLimit: FieldLimitEnum.SURVEY_INFO,
      isRequired: true,
    })
    const description = validateTextField({
      fieldValue: formValues.description,
      fieldName: 'description',
      charLimit: FieldLimitEnum.DESCRIPTION,
      isRequired: false,
    })
    const code = validateTextField({
      fieldValue: formValues.code,
      fieldName: 'project code',
      charLimit: FieldLimitEnum.PROJECT_CODE,
      isRequired: false,
    })
    const project = validateTextField({
      fieldValue: formValues.project,
      fieldName: 'project name',
      charLimit: FieldLimitEnum.PROJECT_NAME,
      isRequired: false,
    })

    const logoBright = !formValues.logoBright && !formValues.logoBrightFile
    const logoDark = !formValues.logoDark && !formValues.logoDarkFile

    const email =
      !formValues.email || formValues.email.toLowerCase().match(INTERNAL_EMAIL_REGEX)
        ? ''
        : 'Must be an Aurecon email address'

    setFormErrors((values) => {
      return {
        ...values,
        name,
        headerText,
        link,
        email,
        description,
        code,
        project,
        logoBright: logoBright ? 'Please select survey logo.' : '',
        logoDark: logoDark ? 'Please select survey logo.' : '',
      }
    })
  }, [formValues])

  const deleteUser = (userEmail: string) => {
    setUsers(users.filter((u) => u != userEmail))
  }

  const addUser = async () => {
    if (formErrors.email) return

    const newEmail = formValues.email.toLowerCase()

    if (newEmail && !users.includes(newEmail) && newEmail !== survey?.author) setUsers((u) => [...u, newEmail])

    setFormValues((values) => {
      return { ...values, email: '' }
    })
  }

  const goBack = () => {
    setNavbarSubTab(null)
    window.location.hash = '/surveys'
  }

  const cancelForm = () => {
    setFormValues(initialValues)
    setFormErrors({})
    goBack()
  }

  const handleValueChange = (field: string, value: string | number | boolean | File | null) => {
    setFormValues((values) => ({ ...values, [field]: value }))
    setFormErrors((values) => ({ ...values, [field]: '' }))
  }

  const hasErrors = useMemo(() => {
    return Object.values(formErrors).some((values) => !!values)
  }, [formErrors])

  const handleDuplicateLinks = () => {
    setFormErrors((values) => ({ ...values, ['link']: 'Survey link already exists.' }))
    addToast({
      type: 'error',
      message: 'Unable to submit the survey',
      timeout: 3000,
    })

    setMainModal(null)
  }

  const reloadSurveys = async (hasError: boolean, surveyId: string, type: string) => {
    if (!hasError) {
      await refreshSurveys()
      setMainModal(null)

      if (type !== 'save') {
        window.location.hash = `/survey/${surveyId}`
        return
      }
      if (editMode) return
      goBack()
    }
  }

  const createPayload = async () => {
    const logoBright = await updateFile('logoBright')
    const logoDark = await updateFile('logoDark')

    const enabled = formValues.enabled === 'yes' ? true : false
    const defaultSurvey = formValues.default === 'yes' ? true : false

    const data = {
      id: surveyId,
      name: formValues.name,
      link: formValues.link,
      enabled,
      default_survey: defaultSurvey,
      header_text: formValues.headerText,
      description: formValues.description,
      logo_bright: logoBright || formValues.logoBright || '',
      logo_dark: logoDark || formValues.logoDark || '',
      users: [] as string[],
      branding: formValues.branding,
      project_code: formValues.code,
      project_name: formValues.project,
      no_preview: formValues.no_preview,
    }

    return data
  }

  const handleResponse = (resDetails: Response | boolean, resUsers: Response | boolean) => {
    let hasError = false
    if (typeof resDetails !== 'boolean' && resDetails.status === 400) {
      hasError = true
      handleDuplicateLinks()
    } else if (typeof resUsers !== 'boolean' && resUsers.status === 401) {
      hasError = true
      addToast({
        type: 'error',
        message: 'Unauthorised access',
        timeout: 3000,
      })
      setMainModal(null)
    }
    return hasError
  }

  const sendAppInsightEvent = (props: IAppInsightCustomEventProps) => {
    try {
      if (appInsights) {
        appInsights?.trackEvent(props)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleGenerateApiKey = async () => {
    if (!surveyId) return
    setApiKeyLoading(true)
    await generateAPIKey({ surveyId })
    await refreshApiKey()
    setApiKeyLoading(false)
  }

  return (
    <AdminLayout disableAnalyseTab={!editMode} disableDesignTab={!editMode}>
      <Container fluid cssClass={Style.mainContainer}>
        <Grid row cssClass={Style.mainGrid}>
          <Grid sm={12} md={3} lg={2} cssClass={Style.leftGrid}>
            <div>
              <h3>{editMode && survey ? 'Edit Survey' : 'Create Survey'}</h3>
              <button className={tab === 'info' ? Style.isActive : ''} onClick={() => setTab('info')}>
                Survey Info
              </button>
              <button className={tab === 'members' ? Style.isActive : ''} onClick={() => setTab('members')}>
                Survey Members
              </button>
            </div>
          </Grid>

          <Grid sm={12} md={9} lg={10} cssClass={Style.rightGrid}>
            {tab === 'info' && (
              <AddEditSurveyInfo
                surveyId={surveyId}
                surveyName={survey.name}
                isAdmin={isAdmin}
                editMode={editMode}
                formValues={formValues}
                formErrors={formErrors}
                hasErrors={hasErrors}
                globalAdmin={globalAdmin}
                apiKey={apiKey}
                apiKeyLoading={apiKeyLoading}
                setFormErrors={setFormErrors}
                handleValueChange={handleValueChange}
                submitForm={submitForm}
                cancelForm={cancelForm}
                handleGenerateApiKey={handleGenerateApiKey}
              />
            )}
            {tab === 'members' && (
              <AddEditSurveyMembers
                editMode={editMode}
                author={survey?.author}
                formValues={formValues}
                formErrors={formErrors}
                hasErrors={hasErrors}
                users={users}
                isAdmin={isAdmin}
                handleValueChange={handleValueChange}
                addUser={addUser}
                deleteUser={deleteUser}
                submitForm={submitForm}
                cancelForm={cancelForm}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  )
}

export default AddEditSurvey
